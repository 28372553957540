import createPersistedState from 'vuex-persistedstate'

export default ({ store }) => {
  return createPersistedState({
    storage: window.localStorage,
    key: 'profile_builder_confirm_state',
    paths: [
      'cart.cart',
      'cart.promoCode',
      'pets.drafts',
      'pets.pets',
      'profileBuilder.id',
      'profileBuilder.visitedStepsName',
      'profileBuilder.petCountFlow',
      'profileBuilder.petIndex',
      'payment.payment',
      'profileBuilder.currentEdition.transactionId',
      'profileBuilder.currentEdition.transactionHt',
      'profileBuilder.currentEdition.transactionTax',
      'profileBuilder.currentEdition.priceShipping',
    ],
  })(store)
}
