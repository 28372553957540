import { sentryConfig } from '@front/common/tracking'
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk'
import * as Sentry from '@sentry/vue'
import Vue from 'vue'

Vue.config.errorHandler = function (err) {
  console.error(err)
}

Sentry.init({
  Vue,
  ...sentryConfig,
  release: process.env.RELEASE_NAME_SHOWCASE,
  dsn: 'https://8e2e59a6b5804efba4613334e4c9eb7f@o576357.ingest.sentry.io/5769469',
})

// initializeFaro({
//   url: 'https://faro-collector-prod-eu-west-2.grafana.net/collect/8c531571884f824c09a805e29cefac6a',
//   app: {
//     name: 'showcase',
//     version: '1.0.0',
//     environment: process.env.APP_ENV,
//   },

//   instrumentations: [
//     // Mandatory, omits default instrumentations otherwise.
//     ...getWebInstrumentations(),

//     // Tracing package to get end-to-end visibility for HTTP requests.
//     // new TracingInstrumentation(),
//   ],
// })
