var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"name":_vm.name}},[_c('img',{class:_vm.$style.icon,attrs:{"src":require("@front/common/assets/Billet_2.svg")}}),_c('RichTranslation',{class:_vm.$style.text,attrs:{"translation":"Date de paiement de la commande le : <slug>{date}</slug>","values":{
      date: _vm.formatDate(_vm.paymentDate, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
      }),
    }},scopedSlots:_vm._u([{key:"slug",fn:function(value){return [_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodyBold}},[_vm._v("\n        "+_vm._s(value)+"\n      ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }