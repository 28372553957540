import { stagger } from 'motion'

export const ANIMATION_DURATION = 0.5

export const FADE_LEFT = {
  keyframes: { opacity: [0, 1], x: [-50, 0] },
  options: { duration: ANIMATION_DURATION, delay: stagger(0.1) },
}

export const POP_UP = {
  keyframes: { opacity: [0, 1], y: [100, 0] },
  options: { duration: ANIMATION_DURATION, delay: stagger(0.1) },
}

export const TITLE_UP = {
  keyframes: { opacity: [1], y: [50, -10, 0] },
  options: { duration: ANIMATION_DURATION * 2 },
}

export const BLOCK_IN = {
  keyframes: { opacity: [0, 1], transform: ['scale(1.2)', 'scale(1)'] },
  options: { duration: ANIMATION_DURATION * 2, delay: stagger(0.1) },
}
