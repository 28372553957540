<template>
  <div>
    <ControlledInputNumber
      v-if="value"
      :is-loading="isLoading"
      :value="value"
      :step="step"
      v-bind="$attrs"
      :min="min"
      :max="max"
      v-on="$listeners"
    >
      <template #default="{ disabled, value, handleInput }">
        <slot :disabled="disabled" :handle-input="handleInput" :value="value" />
      </template>
    </ControlledInputNumber>

    <Button
      v-if="!value"
      :class="$style.button"
      :is-loading="isLoading"
      @click="(click) => $emit('input', Number(step))"
    >
      <Icon name="plus" :class="$style.icon" />
    </Button>
  </div>
</template>

<script>
import { defineComponent } from 'vue-demi'

import { Button, ControlledInputNumber, Icon } from '.'

export default defineComponent({
  components: { Button, ControlledInputNumber, Icon },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: 0,
    },
    step: {
      type: [Number, String],
      default: 1,
    },
    min: {
      type: [Number, String],
      default: 0,
    },
    max: {
      type: [Number, String],
      default: Infinity,
    },
  },
})
</script>

<style lang="scss" module>
.button {
  min-width: initial;
  border-radius: 5px;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  flex-shrink: 0;
  color: white;
}
</style>
