/* eslint-disable camelcase */
export default ({ app }, inject) => {
  async function push(name, query) {
    const {
      editingMode: editingModeQuery,
      code_promo,
      customerId,
      standardisation,
      petIndex,
      petId,
      petStatus,
      context,
      redirectUrl,
    } = app.router.currentRoute.query
    const editingMode = editingModeQuery || false

    await app.router.push({
      name,
      query: {
        ...(editingMode && { editingMode }),
        ...(petStatus && { petStatus }),
        ...(code_promo && { code_promo }),
        ...(customerId && { customerId }),
        ...(standardisation && { standardisation }),
        ...(petId && { petId }),
        ...(petIndex >= 0 && { petIndex }),
        ...(context && { context }),
        ...(redirectUrl && { redirectUrl }),
        ...query,
      },
    })
  }

  inject('goToPageWithQueries', push)
}
