<template>
  <svg
    :class="$style.icon"
    v-bind="{ ...svgAttributes, width: '18px', height: '18px', ...$attrs }"
    v-html="svgChildren"
  />
</template>

<script>
import { defineComponent, toRefs, watch } from 'vue-demi'

import useState from '@front/common/hooks/useState'

export default defineComponent({
  name: 'Icon', // @TODO: remove name when vue-devtools handles it automatically
  props: {
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'regular',
      validator: (value) => ['regular', 'solid'].includes(value),
    },
  },
  setup(props) {
    const { name, type } = toRefs(props)

    const [svgAttributes, setAttributes] = useState(null)
    const [svgChildren, setChildren] = useState(null)

    function getIcon() {
      try {
        // Library assets used from `iconoir` cf https://iconoir.com
        // eslint-disable-next-line @typescript-eslint/no-var-requires, import/no-dynamic-require, global-require
        const icon = require(`!!svg-inline-loader?!iconoir/icons/${type.value}/${name.value}.svg`)

        return icon
      } catch {
        // eslint-disable-next-line @typescript-eslint/no-var-requires, import/no-dynamic-require, global-require
        const icon = require(`!!svg-inline-loader?!../../../common/src/assets/${name.value}.svg`)

        return icon
      }
    }

    function parseSvg(svg) {
      // Extraire l'élément SVG principal
      const svgMatch = svg.match(/<svg\s[^>]*>/i)

      if (!svgMatch) return [null, null] // Si aucun élément SVG n'est trouvé, retourner null

      const svgTag = svgMatch[0]

      // Extraire les attributs de l'élément SVG
      const attributeRegex =
        /(\w+)=["']?((?:.(?!["']?\s+(?:\S+)=|\s*\/?[>"']))+.)["']?/g
      const attributesObject = {}
      let match

      // eslint-disable-next-line no-cond-assign
      while ((match = attributeRegex.exec(svgTag))) {
        // eslint-disable-next-line prefer-destructuring
        attributesObject[match[1]] = match[2]
      }

      // Extraire les enfants de l'élément SVG
      const childrenRegex = /<svg\s[^>]*>([\s\S]*?)<\/svg>/i
      const childrenMatch = svg.match(childrenRegex)
      const childrenArray = childrenMatch ? childrenMatch[1].trim() : ''

      return [attributesObject, childrenArray]
    }

    function generateIcon() {
      try {
        const icon = getIcon()
        const [attributes, children] = parseSvg(icon)

        setAttributes(attributes)
        setChildren(children)
      } catch (error) {
        console.error(error)
        // console.error(`Icon named \`${name.value}\` does not exist`)
      }
    }

    generateIcon()

    watch(name, generateIcon)

    return { svgChildren, svgAttributes }
  },
})
</script>

<style lang="scss" module>
.icon {
  stroke-width: 1.5;
  color: var(--color-grey-60);
}
</style>
