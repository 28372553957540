/* eslint-disable no-param-reassign */
import { FlowType, PromoCode, Subscription } from '@front/common/types'
import { asyncDebounce, isArrayEmpty } from '@front/common/utils'

type cartItem = Subscription

interface Istate {
  cart: cartItem[]
  isRefreshTrialLoading: boolean
  promoCode: PromoCode
}

const initialState = (): Istate => ({
  cart: [],
  isRefreshTrialLoading: false,
  promoCode: null,
})

export default {
  namespaced: true,
  state: initialState,
  getters: {
    get: (state: Istate) => state.cart,
    getSubscription: (state: Istate) => state.cart[0],
    getPromoCode: (state: Istate) => state.promoCode,
    isRefreshTrialLoading: (state: Istate) => state.isRefreshTrialLoading,
  },
  mutations: {
    set(state: Istate, payload: cartItem) {
      state.cart = [payload]
    },
    add(state: Istate, payload: cartItem) {
      state.cart = [...state.cart, payload]
    },
    reset(state: Istate) {
      state.cart = []
    },
    setPromoCode(state: Istate, payload: PromoCode) {
      state.promoCode = payload
    },
    update(state: Istate, payload: cartItem) {
      state.cart = state.cart.map((item) =>
        item.id === payload.id ? payload : item,
      )
    },
    toggleRefreshTrialLoading(state: Istate, payload: boolean) {
      state.isRefreshTrialLoading = payload
    },
  },
  actions: {
    refreshTrial: asyncDebounce(async ({ commit, dispatch, getters }) => {
      commit('toggleRefreshTrialLoading', true)

      await dispatch('trial/fetch', getters.getSubscription.services, {
        root: true,
      })

      commit('toggleRefreshTrialLoading', false)
    }, 300),
    async refresh({ dispatch, state, rootGetters }) {
      const flow = rootGetters['profileBuilder/getFlow']

      if (isArrayEmpty(state.cart) || flow !== FlowType.classic) {
        return Promise.resolve()
      }
      return dispatch('refreshTrial')
    },
    async setPromoCode({ commit, dispatch }, payload) {
      commit('setPromoCode', payload)

      return dispatch('refresh')
    },
    async update({ commit, dispatch }, payload) {
      commit('update', payload)

      return dispatch('refresh')
    },
    async upsert({ commit, dispatch, state }, payload: cartItem) {
      const existingItem = state.cart.some(({ id }) => id === payload.id)

      if (existingItem) {
        commit('update', payload)
      } else {
        commit('set', payload)
      }

      return dispatch('refresh')
    },
    async set({ commit, dispatch }, payload: cartItem) {
      commit('set', payload)

      return dispatch('refresh')
    },
  },
}
